import { createRouter, createWebHistory } from 'vue-router'

import { G } from "@/_common";
// import moment from 'moment'
import NProgress from 'nprogress';

export const routes = [
    {
        // path: '/:lang([a-z]{2})',
        path: '/',
        name: 'home',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/home/Home" } },
        // props: { data: { page: "Agenda", data: {depId: 1} } },
        meta: { requireAuth: true },
        // children: []
    },
    {
        path: '/login',
        name: "login",
        component: () => import('@/layouts/Login'),
        props: { data: {} },
    },

    {
        path: '/role',
        name: 'role',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/role/Role" } },
        meta: { requireAuth: true, requireAdmin: false },
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/user/Users" } },
        meta: { requireAuth: true, requireAdmin: false },
    },

    {
        path: '/category',
        name: 'category',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/category/Category" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/menu',
        name: 'menu',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/menu/Menu" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/device',
        name: 'device',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/device/Device" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/device_type',
        name: 'device_type',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/device_type/DeviceType" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/device_model',
        name: 'device_model',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/device_model/DeviceModel" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/car',
        name: 'car',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/car/Car" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/car_type',
        name: 'car_type',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/car_type/CarType" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/zone',
        name: 'zone',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/zone/Zone" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/profile/Profile" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },
    {
        path: '/customers',
        name: 'customers',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/customers/Customers" } },
        // meta: { requireAuth: true, requireAdmin: true },
    },


    {
        path: '/notperm',
        name: 'notperm',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/NotPermission" } },
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/layouts/Main'),
        props: { data: { page: "pages/NotFound" } },
    },
    {
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import('@/layouts/404'),
        // props: { data: { page: "pages/NotFound" } },
    },

]


const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(),
    history: createWebHistory(),
    base: '',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    var tokenAuth = false;
    var adminRole = false;

    // Error when user not have permission for page name
    if( G.hasClientData("AUTH_TOKEN") ){
        const data = G.getClientData("AUTH_TOKEN").split(".");
        const payload = JSON.parse(atob(data[0]));
        const expires = JSON.parse(atob(data[1]));

        // if (moment(expires.exp, 'DD/MM/YYYY hh:mm:ss') < moment()) {
        if (new Date(expires.exp) < new Date()) {
            G.removeClientData("AUTH_TOKEN");
            tokenAuth = false;
        }
        else if( new Date(expires.exp) > new Date() ) tokenAuth = true;

        if (payload.role == 'admin') {
            adminRole = true;
        }
    }

    if( tokenAuth && !to.matched.some(record => record.name == 'login') &&  !G.getUserAccessToMenu(to.matched[0].name ) ){
        next({
            name: 'notfound',
        });
        return;
    }

    // if ( !to.matched.some(record => record.name == 'login') && ((to.matched.some(record => record.meta.requireAdmin) && !adminRole && !tokenAuth) || (to.matched.some(record => record.meta.requireAuth) && !tokenAuth)) ) {
    // console.log(!to.matched.some(record => record.name == 'login'), to.matched.some(record => record.meta.requireAuth), tokenAuth);
    if (!to.matched.some(record => record.name == 'login') && to.matched.some(record => record.meta.requireAuth) && !tokenAuth) {
        next({
            // path: '/az/login',
            name: 'login',
            query: { redirect: to.fullPath },
            // params: {lang: to.params.lang},
        });
    }
    else if (!to.matched.some(record => record.name == 'login') && to.matched.some(record => record.meta.requireAdmin) && !adminRole) {
        next({
            name: 'notperm',
            // params: { lang: to.params && to.params.lang ? to.params.lang : 'az' },
        });
    }
    else if (!to.matched.some(record => record.name == 'login') && !tokenAuth) {
        // else if (!tokenAuth) {
        next({
            name: 'login',
            // params: { lang: to.params && to.params.lang ? to.params.lang : 'az' },
            query: { redirect: to.fullPath }
        });
    }
    // else if (!to.matched.some(record => record.name == 'login') ) {
    //     let rf = from.fullPath.split('/');
    //     let rt = to.fullPath.split('/');
    //     if (rf.length > 0 && rf[1].length == 2 && rf[1] != rt[1]) {
    //         rf[1] = rt[1];

    //         let translatedPath = rf.join('/') ;
    //         from.fullPath = translatedPath;
    //         from.path = translatedPath;
    //         from.href = translatedPath;
    //         next({ path: translatedPath, query: to.query, hash: to.hash, params: {lang: to.params.lang} });
    //     }
    //     else if (to.path == '/') {
    //         console.log('from root path');
    //         next({
    //             name: 'index',
    //             // force: true,
    //             // query: { redirect: to.fullPath },
    //             params: {lang: 'az'}
    //         });
    //     }
    //     else{
    //         console.log('else route');
    //         next();
    //     }
    // }
    else {
        next();
    }
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
        next()
    } else {
        next()
    }
});

router.afterEach((to, from) => {
    NProgress.done()
});


export default router
