<template>

<div>

    <router-view :key="$route.path" />

    <Toast position="center" group="appToast">
        <template #message="slotProps">
            <div v-if="slotProps.message.type == 'confirm'" class="container d-flex flex-column">
                <div class="text-left d-flex flex-row align-items-center mb-2">
                    <div
                        class="p-toast-message-icon fs-5 me-2"
                        :class="slotProps.message.icon || G.toast.class[slotProps.message.severity]"
                    ></div>
                    <div v-html="slotProps.message.title" class="mb-0 pt-1 fs-5"></div>
                </div>
                <div class="text-center">
                    <p v-html="slotProps.message.detail"></p>
                </div>
                <div class="row text-center mt-1">
                    <div class="col text-end">
                        <button
                            class="btn btn-primary"
                            @click="slotProps.message.yes ? slotProps.message.yes($event) : null"
                        >
                        <i v-if="slotProps.message.yesIcon !== undefined" :class="slotProps.message.yesIcon"></i>
                        {{slotProps.message.yesTitle || 'Oldu'}}</button>
                    </div>
                    <div class="col text-start">
                        <button
                            class="btn btn-secondary"
                            @click="slotProps.message.no ? slotProps.message.no($event) : null"
                        >
                        <i v-if="slotProps.message.noIcon !== undefined" :class="slotProps.message.noIcon"></i>
                        {{slotProps.message.noTitle || 'Xeyr'}}</button>
                    </div>
                </div>
            </div>

            <div v-else class="w-100 d-flex align-items-center">
                <span
                    class="p-toast-message-icon me-2"
                    :class="slotProps.message.icon || G.toast.class[slotProps.message.severity]"
                ></span>
                <div class="p-toast-message-text w-100">
                    <div class="p-toast-summary" v-html="slotProps.message.title"></div>
                    <div class="p-toast-detail my-2 fs-6" v-html="slotProps.message.detail"></div>
                </div>
            </div>
        </template>
    </Toast>

</div>

</template>

<script>
    import "primevue/resources/themes/lara-light-teal/theme.css";
    import "primevue/resources/primevue.min.css";
    import "primeicons/primeicons.css";

    import "@/assets/css/root.css";
    import "@/assets/css/style.css";
    import "@/assets/css/primevue.css";

    import { ref, onMounted } from "vue";
    import { useStore } from "vuex";
    import { useRoute } from "vue-router";

    import { usePrimeVue } from "primevue/config";

    import { useToast } from 'primevue/usetoast';
    import Toast from 'primevue/toast';

    import dayjs from 'dayjs';
    import 'dayjs/locale/az';
    // import utc from 'dayjs/plugin/utc';

    import { G, ajax } from "@/_common";

    export default {
        name: "App",
        components: {
            Toast,
        },
        setup() {
            const store = useStore();
            const route = useRoute();

            const primevue = usePrimeVue();

            G.currentRoute = route;

            G.atoast.ref = useToast();

            const TR = ref({});

            dayjs.locale('az');
            var updateLocale = require('dayjs/plugin/updateLocale');
            var duration = require('dayjs/plugin/duration');
            dayjs.extend(updateLocale);
            dayjs.extend(duration);

            dayjs.updateLocale('az', {
                months: [
                    "Yanvar", "Fevral", "Mart", "Aprel", "May", "İyun",
                    "İyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"
                ],
                monthsShort: [
                    "Yan", "Fev", "Mar", "Apr", "May", "İyn",
                    "İyl", "Avq", "Sen", "Okt", "Noy", "Dek"
                ]
            });

            onMounted(async() => {

                const def_lang = route.params && route.params.lang !== undefined ? route.params.lang : "az";

                store.commit("setLang", def_lang);

                // store.commit("setTranslations", L);
                await ajax({
                    url: store.getters.getTranslations(),
                    success: (e) => {
                        if (e && e.data) store.commit("setTranslations", e.data);
                    },
                    error: (e) => {
                        // const res = e.response || null;
                        G.atoast.error('Server Connection Error !');
                        return null;
                        // var res = error.response || {};
                        // if (res.status == 401) console.log(res.data.msg);
                    }
                });

                TR.value = store.getters.getTranslation();

                primevue.config.locale = {
                    startsWith: TR.value.startsWith,
                    contains: TR.value.contains,
                    notContains: TR.value.notContains,
                    endsWith: TR.value.endsWith,
                    equals: TR.value.equals,
                    notEquals: TR.value.notEquals,
                    noFilter: TR.value.noFilter,
                    lt: TR.value.lt,
                    lte: TR.value.lte,
                    gt: TR.value.gt,
                    gte: TR.value.gte,
                    dateIs: TR.value.dateIs,
                    dateIsNot: TR.value.dateIsNot,
                    dateBefore: TR.value.dateBefore,
                    dateAfter: TR.value.dateAfter,
                    clear: TR.value.clear,
                    apply: TR.value.apply,
                    matchAll: TR.value.matchAll,
                    matchAny: TR.value.matchAny,
                    addRule: TR.value.addRule,
                    removeRule: TR.value.removeRule,
                    accept: TR.value.accept,
                    reject: TR.value.reject,
                    choose: TR.value.choose,
                    upload: TR.value.upload,
                    cancel: TR.value.cancel,
                    dayNames: TR.value.dayNames,
                    dayNamesShort: TR.value.dayNamesShort,
                    dayNamesMin: TR.value.dayNamesMin,
                    monthNames: TR.value.monthNames,
                    monthNamesShort: TR.value.monthNamesShort,
                    today: TR.value.today,
                    weekHeader: TR.value.weekHeader,
                    firstDayOfWeek: TR.value.firstDayOfWeek,
                    dateFormat: TR.value.dateFormat,
                    weak: TR.value.weak,
                    medium: TR.value.medium,
                    strong: TR.value.strong,
                    passwordPrompt: TR.value.passwordPrompt,
                    emptyFilterMessage: TR.value.emptyFilterMessage,
                    emptyMessage: TR.value.emptyMessage,
                };

            });

            return { G };
        },
    };
</script>


<style lang="scss">
    @import "~nprogress/nprogress.css";
    #nprogress .peg {
        box-shadow: 0 0 10px #FFBB00, 0 0 5px #FFBB00;
    }

    #nprogress .bar {
        height: 0px;
        background: red;
    }
    #nprogress .spinner {
        right: auto;
        top: 50%;
        left: 50%;
        z-index: 999999;
    }
    #nprogress .spinner-icon {
        width: 48px;
        height: 48px;
        border: solid 6px transparent;
        border-top-color: rgb(23, 60, 82);
        border-left-color: rgb(23, 60, 82);
        border-radius: 50%;
    }
    .fa,
    .far,
    .fas {
        font-family: "Font Awesome 5 Free" !important;
    }
</style>
