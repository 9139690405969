import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import titleMixin from '@/utils/titleMixin';

import PrimeVue from 'primevue/config';
import { FilterMatchMode } from 'primevue/api';
import ToastService from 'primevue/toastservice';


// import OpenLayersMap from 'vue3-openlayers'
// import 'vue3-openlayers/dist/vue3-openlayers.css'

const ap = createApp(App)

ap.use(store)
ap.use(router)

// ap.use(OpenLayersMap)

ap.mixin(titleMixin)


ap.config.globalProperties.$filters = {
    formatDate: value => {
        let d = value.split('-');
        // let m = new Date(0,d[1],0).toLocaleDateString(store.state.lang, {month: 'short'});
        return `${d[2]}.${d[1]}.${d[0]}`;
        // new Date(value).toLocaleDateString(store.state.lang, {
        //     year: 'numeric',
        //     month: 'short',
        //     day: 'numeric'
        // }),
    },

    replace: (str, txt1, txt2) => {
        let re = RegExp(txt1, "img");
        return str.replace(re, txt2);
    },

    capitalize: str => str != '' ? (str.charAt(0).toUpperCase() + str.slice(1)) : '',

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',

    formatCurrency: (value) => {
        if (value)
            return value.toLocaleString("az-AZ", {
                style: "currency",
                currency: "AZN",
            });
        return value;
    },

}


// ap.config.errorHandler = (err, instance, info) => {
//     return false;
//     // handle error, e.g. report to a service
// }


ap.use(PrimeVue, {
    filterMatchModeOptions: {
        text: [
            FilterMatchMode.STARTS_WITH,
            FilterMatchMode.CONTAINS,
            FilterMatchMode.NOT_CONTAINS,
            FilterMatchMode.ENDS_WITH,
            FilterMatchMode.EQUALS,
            FilterMatchMode.NOT_EQUALS
        ],
        numeric: [
            FilterMatchMode.EQUALS,
            FilterMatchMode.NOT_EQUALS,
            FilterMatchMode.LESS_THAN,
            FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
            FilterMatchMode.GREATER_THAN,
            FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
        ],
        date: [
            FilterMatchMode.DATE_IS,
            FilterMatchMode.DATE_IS_NOT,
            FilterMatchMode.DATE_BEFORE,
            FilterMatchMode.DATE_AFTER
        ],
        statusFilter: [
            FilterMatchMode.EQUALS,
            FilterMatchMode.NOT_EQUALS,
        ],
    }

});

ap.use(ToastService);

// ap.use(Slicksort);

ap.mount('#app')
